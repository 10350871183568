import React from 'react';
import styled from '@emotion/styled';
import Image from 'gatsby-image';

import { colors, dimensions, breakpoints, css, respondFrom } from '@styles';
import ButtonLink from '@components/common/ButtonLink';
import Badge from '@components/common/Badge';
import { HomepageComprehensiveItem } from '@content/types/homepage';
import * as utils from '@utils';

const StyledProductsItem = styled.div`
  width: 100%;
  max-width: 400px;
  padding: 10px;

  ${respondFrom(
    breakpoints.lg,
    css`
      width: 33.3333%;
      padding: ${dimensions.boxesSpacing / 2}px;
    `
  )}
`;

const StyledProductsItemInner = styled.div`
  background-color: ${colors.ui.whisper};
  padding: 40px 50px;
  position: relative;
  overflow: hidden;
  margin: 0;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  h3 {
    font-size: 30px;
    margin-bottom: 0.3em;
  }

  h4 {
    font-size: 16px;
  }

  .indicator-online {
    display: inline-block;
    text-transform: uppercase;
    font-size: ${dimensions.fontSize.smaller}px;
    color: ${colors.white};
    background-color: ${colors.blue.default};
    width: 50%;
    text-align: center;
    vertical-align: middle;
    padding: 4px 0 3px;
    font-weight: 500;

    position: absolute;
    top: 25px;
    right: -60px;
    transform: rotate(45deg);
  }
`;

const StyledImage = styled(Image)`
  align-self: center;
  margin-bottom: 10px;
`;

const StyledButtonLink = styled(ButtonLink)`
  align-self: center;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

interface ProductItemProps extends HomepageComprehensiveItem {
  langcode: string;
  urls: Array<string>;
}

export default (props: ProductItemProps) => {
  return (
    <StyledProductsItem>
      <StyledProductsItemInner>
        <div>
          {utils.SafeHtml(props.title)}

          {props.hasOnline ? <Badge text={props.online} /> : ''}
        </div>
        {props.image && (
          <StyledImage
            {...props.image.childImageSharp}
            imgStyle={{ objectFit: 'contain' }}
            alt={props.imageAlt}
          />
        )}
        <ButtonWrapper>
          <StyledButtonLink to={utils.langLink(props.langcode, props.urls[4])} icon="arrow-right">
            {props.button}
          </StyledButtonLink>
        </ButtonWrapper>
      </StyledProductsItemInner>
    </StyledProductsItem>
  );
};
