import React from 'react';
import styled from '@emotion/styled';
import { respondFrom, breakpoints, css, colors} from '@styles';
import ButtonLink from '@components/common/ButtonLink';

const StyledHeaders = styled.div<StyledHeadersProps>`
  text-align: left;
  max-width: 900px;
  margin: 40px auto 50px;

  ${props => props.isInactive && css`
    opacity: 0.5;
    border-color: ${colors.gray} !important;
  `};

  ${respondFrom(
    breakpoints.lg,
    css`
      text-align: center;
    `
  )}

  h2 {
    display: inline-block;
    line-height: 1.3;
  }

  p {
    line-height: 1.5;
    max-width: 1000px;
  }
`;

interface SectionHeaderProps extends StyledHeadersProps {
  title?: string | React.ReactElement;
  paragraph?: string | React.ReactElement;
  buttonLabel?: string;
  buttonHref?: string;
}

interface StyledHeadersProps {
  isInactive?: boolean;
}

export default (props: SectionHeaderProps) => (
  <StyledHeaders
    isInactive={props.isInactive}
  >
    {props.title && props.title !== '' && <h2>{props.title}</h2>}
    {props.paragraph && <div>{props.paragraph}</div>}
    {props.buttonLabel && props.buttonHref && (
      <ButtonLink to={props.buttonHref} color="text" icon="arrow-right" size="small">
        {props.buttonLabel}
      </ButtonLink>
    )}
  </StyledHeaders>
);
