import React from 'react';
import Page from '@components/layout/Page';

import HomepageWrapper from '@components/home';
import MetaPage from '@components/common/MetaPage';
import DataLayer from '@components/layout/DataLayer';
import { LocationDependent } from '@content/types/general';
import useHomepageQuery from '@graphql/queries/HomepageQuery';
import { PageContext } from '@content/types/pageContext';

interface Props extends LocationDependent {
  pageContext: PageContext;
}

const Homepage = (props: Props) => {
  const homepageData = useHomepageQuery()[props.pageContext.langcode];

  return (
    <Page>
      <MetaPage title={homepageData.seo.title} description={homepageData.seo.description} />
      <DataLayer location={props.location} />
      <HomepageWrapper
        homepageData={homepageData}
        langcode={props.pageContext.langcode}
        urls={props.pageContext.allUrls}
      />
    </Page>
  );
};

export default Homepage;
