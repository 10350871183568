import React from 'react';
import styled from '@emotion/styled';
import useBranchesQuery from '@graphql/queries/BranchesQuery';

import { ImageSharp } from '@content/types/general';
import { dimensions, respondFrom, breakpoints, css } from '@styles';
import Container from '../layout/Container';
import HomeBranchesItemSmall, { HomeBranchesItemSmallTextBreak } from './HomeBranchesItemSmall';
import HomeBranchesItemLarge from './HomeBranchesItemLarge';
import { HomepageBranchLargeData, HomepageBranchBottomData } from '@content/types/homepage';
import { Branch } from '@content/types/branch';
import * as utils from '@utils';

const StyledHomeBranchesList = styled.div`
  padding-bottom: 80px;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  margin: 0 -${dimensions.boxesSpacing / 2}px;
`;

const StyledHeaders = styled.div`
  text-align: left;
  max-width: 900px;
  margin: 40px auto 50px;

  ${respondFrom(
    breakpoints.lg,
    css`
      text-align: center;
    `
  )}
`;

/* --- */
interface BranchesQuery {
  allBranchesJson: {
    edges: {
      node: Branch;
    }[];
  };
  largeBranchOverlayImage: ImageSharp;
  noBranchBoxImage: ImageSharp;
}

interface HomeBranchesProps {
  data?: BranchesQuery;
  branchBottom: HomepageBranchBottomData;
  branchLarge: HomepageBranchLargeData;
  langcode: string;
  urls: Array<string>;
}

const HomeBranches = ({ data, branchBottom, branchLarge, langcode, urls }: HomeBranchesProps) => {
  return (
    <Container>
      <div>
        <StyledHeaders>{utils.SafeHtml(branchLarge.title)}</StyledHeaders>

        <StyledHomeBranchesList>
          {useBranchesQuery().allBranches[langcode].map((branch, index) => {
            return index === 0 ? (
              <HomeBranchesItemLarge
                {...branch}
                overlayImage={branchLarge.image ? branchLarge.image : undefined}
                overlayImageAlt={branchLarge.imageAlt}
                paragraph={branchLarge.paragraph}
                button={branchLarge.button}
                buttonHref={branchLarge.buttonHref}
                isOnline={branchLarge.isOnline}
                isOnlineText={branchLarge.isOnlineText}
                key={index}
                langcode={langcode}
                urls={urls}
              />
            ) : branch.fromDate ? (
              <HomeBranchesItemSmall
                {...branch}
                key={index}
                space="large"
                langcode={langcode}
                urls={urls}
              />
            ) : null;
          })}

          {/* text break before non-fromDate branches */}
          <HomeBranchesItemSmallTextBreak 
            paragraph={branchBottom.paragraph} 
            buttonLabel={branchBottom.button}  
            langcode={langcode}
            urls={urls} 
          />
        </StyledHomeBranchesList>
      </div>
    </Container>
  );
};

export default HomeBranches;
