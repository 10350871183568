import React from 'react';
import styled from '@emotion/styled';

import { colors, mixins, respondFrom, respondTo, breakpoints, css } from '@styles';
import Container from '@components/layout/Container';
import { HomepageHeroData } from '@content/types/homepage';
import * as utils from '@utils';

import imagePiggie from '@assets/svg/skarbonka.svg';

const StyledSavingsNotice = styled.div`
  ${respondTo(
    breakpoints.lg,
    css`
      background: ${colors.ui.whisper};
    `
  )}

  img {
    position: absolute;
    bottom: 0;
    right: 15px;
    width: 80px;

    ${respondFrom(
      breakpoints.lg,
      css`
        width: 65px;
        left: 15px;
        right: auto;
      `
    )}

    ${respondFrom(
      breakpoints.xxl,
      css`
        width: 80px;
        left: -4vw;
      `
    )}
  }
`;

const StyledSavingsNoticeInner = styled.div`
  color: ${colors.text.default};
  position: relative;
  padding: 0 0 60px;

  ${respondFrom(
    breakpoints.lg,
    css`
      width: 90%;
      max-width: 1100px;
      padding: 2.2em 4% 2.2em 0;
      display: flex;
      flex-direction: row;
      align-items: center;

      .fullwidth & {
        width: 100%;
        margin: 0 auto;
      }

      .with-video & {
        flex-direction: column;
        align-items: flex-start;

        .arrow-down {
          bottom: calc((vw(100) / 16) * 9);
        }

        h3,
        div {
          max-width: 50%;
        }
      }
    `
  )}

  ${respondTo(
    breakpoints.lg,
    css`
      .fullwidth & {
        margin-top: 30px;
      }
    `
  )}

  &:before {
    /* general bg */
    content: '';
    ${mixins.absoluteFill}
    left: -500%;
    background: ${colors.ui.whisper};
    z-index: -1;

    .fullwidth & {
      right: -500%;
    }
  }

  &:after {
    content: '';
    position: absolute;
    background: ${colors.ui.whisper};

    ${respondTo(
      breakpoints.lg,
      css`
        /* top fill (mobile) */
        left: -100%;
        right: 20%;
        bottom: auto;
        top: -30px;
        height: 30px;

        .fullwidth & {
          right: -100%;
        }
      `
    )}
  }

  h3 {
    font-size: 19px;
    ${respondTo(
      640,
      css`
        max-width: 70%;
        strong {
          display: block;
          font-size: 50px;
        }
      `
    )}
    ${respondFrom(
      640,
      css`
        font-size: 30px;
        line-height: 1.73;
        white-space: nowrap;
        padding-right: 5%;
        margin-bottom: 0;

        strong {
          font-size: inherit;
        }
      `
    )}
  }

  p {
    line-height: 1.5;
    margin-bottom: 0;
  }
`;

interface Props {
  fullWidth?: boolean;
  additionalContent?: React.ReactElement | null;
  className?: string;
  data: HomepageHeroData;
  withVideo?: boolean;
}

export default (props: Props) => (
  <StyledSavingsNotice
    className={`
    ${props.fullWidth ? 'fullwidth' : ''}
    ${props.withVideo ? 'with-video' : ''}
    ${props.className || ''}
  `}
  >
    <Container>
      <StyledSavingsNoticeInner>
        <h3>{utils.SafeHtml(props.data.text1)}</h3>
        {utils.SafeHtml(props.data.text2)}
      </StyledSavingsNoticeInner>

      <img src={imagePiggie} alt="" />

      {props.additionalContent || null}
    </Container>
  </StyledSavingsNotice>
);
