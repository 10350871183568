import React from 'react';
import { useSpring, useSprings, animated, config } from 'react-spring';
import { Stylable } from '@content/types/general';
import { WaypointDependent } from './AnimationTriggerWaypoint';

interface Props extends Stylable, WaypointDependent {}

const strokeDasharrays = [80, 150, 150];
const initialDelay = 500;
const stepsDelay = 300;

export default (props: Props) => {
  // lines animation
  const [animLines, animLinesSet] = useSprings(3, i => ({
    from: { strokeDashoffset: 1 * strokeDasharrays[i] },
    config: config.molasses,
  }));
  animLinesSet(i => ({
    strokeDashoffset: props.sectionVisible ? 0 : 1 * strokeDasharrays[i],
    delay: initialDelay + i * stepsDelay,
  }));

  // dots animation
  const [animDots, animDotsSet] = useSprings(7, i => ({
    from: { opacity: 0 /*, transform: 'scale(0)'*/ },
    config: config.default,
  }));
  animDotsSet(i => ({
    opacity: props.sectionVisible ? 1 : 0,
    // transform: props.sectionVisible ? 'scale(1)' : 'scale(0)',
    delay: initialDelay + 1500 + i * 300,
  }));

  // icon animation
  const [animIcon, animIconSet] = useSpring(i => ({
    from: { opacity: 0, transform: 'translate(2%, 2%)' },
    config: config.subtle,
  }));
  animIconSet({
    opacity: props.sectionVisible ? 1 : 0,
    transform: props.sectionVisible ? 'translate(0, 0)' : 'translate(2%, 2%)',
    delay: initialDelay + 3500,
  });

  return (
    <div className={props.className}>
      <svg xmlns="http://www.w3.org/2000/svg" width="195" height="189" viewBox="0 0 195 189">
        <g fill="none" fillRule="evenodd">
          {/* icon */}
          <animated.g style={animIcon} stroke="#FFF" strokeWidth="1.5">
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M29.95 40.2h8.57s-.203 2.323.229 4.606c.332 1.75 1.543 3.302 3.336 4.278.501.272.267.916-.334.916H18.15c-.6 0-.835-.644-.334-.916 1.793-.976 3.005-2.529 3.336-4.278.432-2.283.23-4.606.23-4.606h8.569zM29.95 37.342a.411.411 0 0 1-.413.408.411.411 0 0 1-.414-.408c0-.226.186-.409.414-.409.228 0 .414.183.414.409z"
            />
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M48.975 40.2H3.828C2.266 40.2 1 38.998 1 37.516v-3.85h11.09"
            />
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M49.404 33.667H1V4.106A3.101 3.101 0 0 1 4.097 1h51.708c1.71 0 3.096 1.39 3.096 3.106v14.42"
            />
            <path d="M66.346 43.875H51.457M66.54 50H50.435c-.806 0-1.46-.648-1.46-1.446V20.413c0-.799.654-1.446 1.46-1.446H66.54c.806 0 1.46.647 1.46 1.446v28.14c0 .799-.654 1.447-1.46 1.447z" />
            <path d="M59.728 46.325a.41.41 0 0 0-.413-.408.411.411 0 0 0-.414.408c0 .225.185.408.414.408a.411.411 0 0 0 .413-.408z" />
            <path strokeLinecap="round" strokeLinejoin="round" d="M56.42 21.825h4.136" />
          </animated.g>
          {/* lines */}
          <g strokeLinecap="round" strokeLinejoin="round">
            {/* 1 + 2 */}
            <animated.path
              strokeDasharray={strokeDasharrays[2]}
              strokeDashoffset={animLines[2].strokeDashoffset}
              stroke="#FFF"
              d="M87.58 72.354c-31.167 33.27-29.338 85.39 4.084 116.415M190.527 84.093c-19.674-18.12-48.08-21.215-70.723-9.781a62.001 62.001 0 0 0-17.16 12.813"
            />
            {/* 3 */}
            <animated.path
              strokeDasharray={strokeDasharrays[1]}
              strokeDashoffset={animLines[1].strokeDashoffset}
              stroke="#FFF"
              d="M183.115 99.656c-18.787-17.216-48.22-16.206-65.74 2.256-9.249 9.746-13.328 22.409-12.355 34.724.871 11.013 5.782 21.749 14.651 29.876"
            />
            {/* 4 */}
            <animated.path
              strokeDasharray={strokeDasharrays[0]}
              strokeDashoffset={animLines[0].strokeDashoffset}
              stroke="#094"
              d="M161.123 113.637A19.423 19.423 0 0 0 148.5 109c-10.77 0-19.5 8.73-19.5 19.5 0 7.719 4.485 14.39 10.991 17.55A19.422 19.422 0 0 0 148.5 148"
            />
          </g>
          {/* dots */}
          <g transform="translate(69 56)">
            <animated.ellipse
              style={animDots[2]}
              cx="64.932"
              cy="59.933"
              fill="#094"
              rx="2.068"
              ry="2.067"
            />
            <animated.ellipse
              style={animDots[3]}
              cx="48.389"
              cy="45.053"
              fill="#FFF"
              rx="2.068"
              ry="2.067"
            />
            <animated.ellipse
              style={animDots[4]}
              cx="32.673"
              cy="31"
              fill="#FFF"
              rx="2.068"
              ry="2.067"
            />
            <animated.ellipse
              style={animDots[5]}
              cx="17.957"
              cy="16.12"
              fill="#FFF"
              rx="3.722"
              ry="3.72"
            />
            <animated.ellipse
              style={animDots[6]}
              cx="3.722"
              cy="3.72"
              fill="#FFF"
              rx="3.722"
              ry="3.72"
            />
            <animated.circle style={animDots[1]} cx="121.5" cy="27.5" r="4.5" fill="#FFF" />
            <animated.circle style={animDots[1]} cx="49.5" cy="109.5" r="4.5" fill="#FFF" />
          </g>
        </g>
      </svg>
    </div>
  );
};
