import React from 'react';
import { useSpring, useSprings, animated, config } from 'react-spring';
import { Stylable } from '@content/types/general';
import { WaypointDependent } from '@animations/AnimationTriggerWaypoint';

interface Props extends Stylable, WaypointDependent {}

const initialDelay = 1000;

export default (props: Props) => {
  // icon animation
  const [animIcon, animIconSet] = useSpring(i => ({
    from: { opacity: 0, transform: 'scale(0)' },
    config: config.wobbly,
  }));
  animIconSet({
    opacity: props.sectionVisible ? 1 : 0,
    transform: props.sectionVisible ? 'scale(1)' : 'scale(0)',
    delay: initialDelay,
  });

  return (
    <div className={props.className}>
      <svg xmlns="http://www.w3.org/2000/svg" width="425" height="284" viewBox="0 0 425 284">
        <g fill="none" fillRule="evenodd">
          <path
            stroke="#02A2DC"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M189 209.001a3 3 0 1 1-6.001-.002 3 3 0 0 1 6.001.002z"
          />
          <path
            stroke="#C9C9C9"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M186 229h50.417s-1.192 12.8 1.35 25.38c1.949 9.639 9.077 18.199 19.625 23.569 2.95 1.504 1.573 5.051-1.965 5.051H116.57c-3.535 0-4.913-3.547-1.962-5.051 10.548-5.37 17.676-13.93 19.625-23.569 2.54-12.58 1.348-25.38 1.348-25.38H186z"
          />
          <path
            stroke="#C9C9C9"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M358 107.615V212.77c0 8.964-7.228 16.231-16.15 16.231H17.153C8.233 229 1 221.733 1 212.77V17.23C1 8.268 8.233 1 17.153 1H341.85C350.77 1 358 8.267 358 17.23v59.068"
          />
          <animated.g
            stroke="#094"
            strokeLinecap="round"
            style={{ transformOrigin: '96% 58%', ...animIcon }}
          >
            <path
              strokeWidth="2"
              d="M384 173c0 8.837 7.163 16 16 16 8.836 0 16-7.163 16-16 0-8.836-7.164-16-16-16-8.837 0-16 7.164-16 16z"
            />
            <path d="M394 173.595l5.346 5.405L408 167" />
          </animated.g>
          <path
            fill="#C9C9C9"
            fillRule="nonzero"
            d="M386.506 88.45c7.456 0 13.5 6.045 13.5 13.5v39.38a4.5 4.5 0 1 1-1 0v-39.38c0-6.903-5.596-12.5-12.5-12.5h-17.308c-5.908 0-10.698 4.79-10.698 10.698h-1c0-6.46 5.237-11.698 11.698-11.698z"
          />
        </g>
      </svg>
    </div>
  );
};
