import React, { useState } from 'react';
import styled from '@emotion/styled';
import { useSpring, animated, config } from 'react-spring';
import { respondTo, respondFrom, fluidRange, breakpoints, css } from '@styles';

import Container from '@components/layout/Container';
import { Waypoint } from 'react-waypoint';
import { WaypointDependent } from '@animations/AnimationTriggerWaypoint';

const StyledSplitSection = styled.section``;

const StyledContainer = styled(Container)`
  padding-top: 50px;
  padding-bottom: 50px;

  ${respondFrom(
    breakpoints.md,
    css`
      display: flex;
      flex-direction: row;
      align-items: center;
    `
  )}

  > * {
    ${respondTo(
      breakpoints.md,
      css`
        &:last-of-type {
          margin-top: 30px; /* mobile - space between two parts */
        }
      `
    )}
    ${respondFrom(
      breakpoints.md,
      css`
        width: 50%;

        &:first-of-type {
          padding-right: 8%;
        }
        &:last-of-type {
          padding-left: 8%;
        }
      `
    )}
  }

  &.reversed {
    flex-direction: row-reverse;

    > * {
      ${respondFrom(
        breakpoints.md,
        css`
          &:first-of-type {
            padding-left: 8%;
            padding-right: 0;
          }
          &:last-of-type {
            padding-right: 8%;
            padding-left: 0;
          }
        `
      )}
    }
  }

  h2 {
    ${fluidRange('fontSize', '25px', '30px')}
    line-height: 1.2;
  }

  p {
    line-height: 1.5;
  }
`;

const StyledSplitA = styled.div``;

const StyledSplitB = styled.div``;

interface SplitSectionProps extends WaypointDependent {
  contentA?: React.ReactElement | null;
  contentB?: React.ReactElement | null;
  imageOnLeft?: boolean;

  className?: string;
}

const SplitSection: React.FC<SplitSectionProps> = ({
  className,
  imageOnLeft,
  sectionVisible,
  contentA,
  contentB,
}) => {
  const [visibility, setVisibility] = useState(false);

  if (sectionVisible && !visibility) {
    // if visibility info comes from outer component - override local waypoint info
    setVisibility(true);
  }

  const props1 = useSpring({
    from: { opacity: 0, transform: `translateX(${!imageOnLeft ? '-' : ''}20px)` },
    to: {
      opacity: visibility ? 1 : 0,
      transform: visibility ? 'translateX(0)' : `translateX(${!imageOnLeft ? '-' : ''}20px)`,
    },
    config: config.subtle,
  });

  const props2 = useSpring({
    from: { opacity: 0, transform: `translateX(${imageOnLeft ? '-' : ''}20px)` },
    to: {
      opacity: visibility ? 1 : 0,
      transform: visibility ? 'translateX(0)' : `translateX(${imageOnLeft ? '-' : ''}20px)`,
    },
    config: config.subtle,
    delay: 300,
  });

  return (
    <StyledSplitSection
      className={`
    ${className || ''}
    ${imageOnLeft ? 'image-left' : ''}
  `}
    >
      <StyledContainer
        className={`
      ${imageOnLeft ? 'reversed' : ''}
    `}
      >
        <StyledSplitA>
          <Waypoint
            topOffset={300}
            bottomOffset={300}
            // onEnter={() => {
            //   setVisibility(true);
            // }}
            // onLeave={() => {
            //   setVisibility(false);
            // }}
          >
            <animated.div style={props1}>{contentA}</animated.div>
            {/* <div>{contentA}</div> */}
          </Waypoint>
        </StyledSplitA>
        <StyledSplitB>
          <animated.div style={props2}>{contentB}</animated.div>
          {/* <div>{contentB}</div> */}
        </StyledSplitB>
      </StyledContainer>
    </StyledSplitSection>
  );
};

export default SplitSection;
