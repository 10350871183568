import { graphql, useStaticQuery } from 'gatsby';
import * as homepageTransformers from '@content/transformers/HomepageTransformers';

const HomepageQuery = () => {
  const homepageData = useStaticQuery(graphql`
    query {
      allSiteSettingEntityMainPage {
        edges {
          node {
            ...IposApiHomepageFragment
          }
        }
      }
    }
  `);

  return homepageTransformers.transformHomepageData(homepageData.allSiteSettingEntityMainPage);
};

export default HomepageQuery;
