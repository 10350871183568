import React from 'react';
import styled from '@emotion/styled';
import Container from '@components/layout/Container';
import { BlogArticleItem } from '@components/blog/BlogArticleItem';
import { breakpoints, respondFrom, css } from '@styles';
import { HomepageRecommendedBlogPostsData } from '@content/types/homepage';

const StyledHeaders = styled.h2`
  text-align: left;
  max-width: 900px;
  margin: 40px auto 50px;

  ${respondFrom(
    breakpoints.lg,
    css`
      text-align: center;
    `
  )}
`;

const StyledArticlesList = styled.div`
  display: flex;
  flex-flow: column;

  ${respondFrom(
    breakpoints.md,
    css`
      flex-flow: row wrap;
      justify-content: space-between;
    `
  )}
`;
interface HomeSectionBlogProps {
  recommendedBlogPosts: HomepageRecommendedBlogPostsData;
  langcode: string;
  urls: Array<string>;
}

export default (props: HomeSectionBlogProps) => {
  return (
    <Container>
      {props.recommendedBlogPosts.headline && (
        <StyledHeaders>{props.recommendedBlogPosts.headline}</StyledHeaders>
      )}
      {props.recommendedBlogPosts.items && (
        <StyledArticlesList>
          {props.recommendedBlogPosts.items.map((article, index) => (
            <BlogArticleItem
              key={index}
              article={article}
              langcode={props.langcode}
              urls={props.urls}
              row={3}
              noIntroduction
              readMoreButtonText={props.recommendedBlogPosts.readMoreButtonText}
              heightAuto
            />
          ))}
        </StyledArticlesList>
      )}
    </Container>
  );
};
