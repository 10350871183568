import React, { useState } from 'react';
import styled from '@emotion/styled';
import Image from 'gatsby-image';
import SectionOverlapping from '@components/common/SectionOverlapping';
import ButtonLink from '@components/common/ButtonLink';
import Container from '@components/layout/Container';
import AnimationTriggerWaypoint from '@animations/AnimationTriggerWaypoint';
import { HomepageCashLessData } from '@content/types/homepage';
import * as utils from '@utils';
import AnimationCircle from '@animations/AnimationCircle';

import pbLogo from '@assets/svg/pb-logo-monocolor.svg';

const Wrapper = styled.div`
  position: relative;
`;

const StyledContent = styled.div`
  h3 {
    font-size: 40px;
    line-height: 1.3;
  }
`;

const StyledButtonLink = styled(ButtonLink)`
  align-self: flex-start;
`;

const AnimationCircleWrapper = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: -100px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: -1;
`;

interface HomeSectionCashlessProps {
  data: HomepageCashLessData;
  langcode: string;
  urls: Array<string>;
}

export default (props: HomeSectionCashlessProps) => {
  const [visibility, setVisibility] = useState(false);

  return (
    <Wrapper>
      <AnimationTriggerWaypoint onChange={setVisibility}>
        <Container>
          <SectionOverlapping
            imageContent={props.data.image && <Image {...props.data.image.childImageSharp} />}
            bonusImage={<img src={pbLogo} alt="" />}
            content={
              <StyledContent>
                {utils.SafeHtml(props.data.paragraph)}

                <StyledButtonLink
                  to={utils.langLink(props.langcode, props.data.buttonHref)}
                  icon="arrow-right"
                  color="text"
                >
                  {props.data.buttonLabel}
                </StyledButtonLink>
              </StyledContent>
            }
          />
        </Container>

        <AnimationCircleWrapper>
          <AnimationCircle
            sectionVisible={visibility}
            className="size-large"
            animateReverse={true}
          />
        </AnimationCircleWrapper>
      </AnimationTriggerWaypoint>
    </Wrapper>
  );
};
