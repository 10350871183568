import React from 'react';
import styled from '@emotion/styled';

import Hero from '@components/common/Hero';
import { respondFrom, breakpoints, css } from '@styles';
import SavingsNotice from '@components/home/HomeSavingsNotice';
import AnimationHomeHero from '../../animations/AnimationHomeHero';
import AnimationArrowDown from '../../animations/AnimationArrowDown';
import { HomepageHeroData } from '@content/types/homepage';
import * as utils from '@utils';
import HeroVideoPlayer from '@components/common/HeroVideoPlayer';

const StyledHero = styled(Hero)`
  ${respondFrom(
    breakpoints.lg,
    css`
      img {
        object-position: top center !important;
      }
    `
  )}
`;

const StyledSavingsNotice = styled(SavingsNotice)`
  ${props =>
    respondFrom(
      breakpoints.lg,
      css`
        position: absolute;
        left: 0;
        bottom: ${props.withVideo ? '-130px' : '-110px'};
        width: 100%;
        right: 50%;
      `
    )}
`;

const StyledArrowDown = styled(AnimationArrowDown)`
  position: absolute;
  bottom: -50px;
  left: 0;
  right: 0;

  ${respondFrom(
    breakpoints.lg,
    css`
      bottom: -200px;
    `
  )}
`;

const StyledAnimationHomeHero = styled(AnimationHomeHero)`
  display: none;

  ${respondFrom(
    breakpoints.md,
    css`
      display: block;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-15%, 10%);
    `
  )}
`;

interface HomeHeroProps {
  data: HomepageHeroData;
  langcode: string;
  urls: Array<string>;
}

export default (props: HomeHeroProps) => {
  return (
    <StyledHero
      size="large"
      image={props.data.image ? props.data.image.childImageSharp : undefined}
      imageMobile={props.data.imageMobile ? props.data.imageMobile.childImageSharp : undefined}
      imageAlt={props.data.imageAlt}
      title={props.data.title}
      paragraph={utils.SafeHtml(props.data.subtitle)}
      buttonText={props.data.button}
      buttonRoute={utils.langLink(props.langcode, props.urls[4])}
      loading='eager'
      additionalContent={
        <>
          <StyledSavingsNotice {...props} withVideo={!!props.data.video} />
          <StyledAnimationHomeHero sectionVisible={true} />
          {props.data.video && (
            <HeroVideoPlayer
              videoThumbnailAlt={props.data.videoThumbnailAlt}
              videoThumbnail={props.data.videoThumbnail}
              videoUrl={props.data.video}
            />
          )}
          <StyledArrowDown />
        </>
      }
    ></StyledHero>
  );
};
