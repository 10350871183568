import React from 'react';
import styled from '@emotion/styled';
import { respondFrom, breakpoints, css } from '@styles';
import Image from 'gatsby-image';
import { Link } from 'gatsby';

import { Branch } from '@content/types/branch';
import Badge from '@components/common/Badge';
import ButtonLink from '@components/common/ButtonLink';
import SectionOverlapping from '@components/common/SectionOverlapping';
import { ImageSharp } from '@content/types/general';
import * as utils from '@utils';

const StyledBranchesItem = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-bottom: 50px;

  ${respondFrom(
    breakpoints.xl,
    css`
      margin-bottom: 20px;
    `
  )}
`;

const StyledButtonLink = styled(ButtonLink)`
  align-self: flex-start;
  margin-top: 30px;
`;

interface HomeBranchesItemLargeProps extends Branch {
  overlayImage?: ImageSharp;
  overlayImageAlt?: string;
  paragraph: string;
  button: string;
  isOnline: boolean;
  isOnlineText: string;
  buttonHref: string;
  langcode: string;
  urls: Array<string>;
}

export default (props: HomeBranchesItemLargeProps) => (
  <StyledBranchesItem onMouseOver={() => {}}>
    <SectionOverlapping
      imageContent={props.image_large ? <Image {...props.image_large.childImageSharp} /> : null}
      content={
        <>
          {props.isOnline ? <Badge text={props.isOnlineText} /> : ''}
          {utils.SafeHtml(props.paragraph)}

          <StyledButtonLink
            to={utils.langLink(props.langcode, props.buttonHref)}
            icon="arrow-right"
            className="no-parent-hovering"
            longText={true}
          >
            {props.button}
          </StyledButtonLink>
        </>
      }
      overlayImage={props.overlayImage || undefined}
      overlayImageAlt={props.overlayImageAlt}
      contentTo={
        props.slug ? utils.langLink(props.langcode, `${props.urls[3]}/${props.slug}`) : null
      }
    />
  </StyledBranchesItem>
);
