import React from 'react';
import { useSpring, useSprings, animated, config } from 'react-spring';
import { Stylable } from '@content/types/general';
import { WaypointDependent } from '@animations/AnimationTriggerWaypoint';

interface Props extends Stylable, WaypointDependent {}

const strokeDasharrays = [200, 100];
const initialDelay = 1000;
const stepsDelay = 400;

export default (props: Props) => {
  // lines animation
  const [animLines, animLinesSet] = useSprings(2, i => ({
    from: { strokeDashoffset: (i === 0 ? -1 : 1) * strokeDasharrays[i] },
    config: config.molasses,
  }));
  animLinesSet(i => ({
    strokeDashoffset: props.sectionVisible ? 0 : (i === 0 ? -1 : 1) * strokeDasharrays[i],
    delay: initialDelay + i * stepsDelay,
  }));

  // dots animation
  const [animDots, animDotsSet] = useSprings(2, i => ({
    from: { opacity: 0 /*, transform: 'scale(0)'*/ },
    config: config.default,
  }));
  animDotsSet(i => ({
    opacity: props.sectionVisible ? 1 : 0,
    // transform: props.sectionVisible ? 'scale(1)' : 'scale(0)',
    delay: initialDelay + strokeDasharrays[i] * 3 + i * stepsDelay,
  }));

  // icon animation
  const [animIcon, animIconSet] = useSpring(i => ({
    from: { opacity: 0, transform: 'translate(-3%, 0%)' },
    config: config.subtle,
  }));
  animIconSet({
    opacity: props.sectionVisible ? 1 : 0,
    transform: props.sectionVisible ? 'translate(0, 0)' : 'translate(-3%, 0%)',
    delay: initialDelay + 1000,
  });

  return (
    <div className={props.className}>
      <svg xmlns="http://www.w3.org/2000/svg" width="230" height="356" viewBox="0 0 230 356">
        <g fill="none" fillRule="evenodd">
          {/* linie */}
          <g transform="translate(71 44)">
            {/* 1 (zielona) */}
            <g>
              <animated.path
                strokeDasharray={strokeDasharrays[0]}
                strokeDashoffset={animLines[0].strokeDashoffset}
                stroke="#094"
                d="M8 281c12.567 0 24.7-1.863 36.141-5.328m21.19-8.63a125.062 125.062 0 0 0 22.41-14.912M133 158.035C133 89.532 77.036 34 8 34"
              />
              <animated.circle style={animDots[0]} cx="132.5" cy="160.5" r="4.5" fill="#094" />
              <animated.circle style={animDots[0]} cx="8.5" cy="280.5" r="4.5" fill="#094" />
            </g>
            {/* 2 (szara) */}
            <g>
              <animated.path
                strokeDasharray={strokeDasharrays[1]}
                strokeDashoffset={animLines[1].strokeDashoffset}
                stroke="#C9C9C9"
                d="M4 311c17.809 0 34.911-3.013 50.823-8.556m16.322-6.764a154.21 154.21 0 0 0 31.168-20.025M158 157.5c0-31.474-9.504-60.736-25.807-85.09M91.777 31.36C66.875 14.11 36.622 4 4 4"
              />
              <animated.circle style={animDots[1]} cx="102.5" cy="275.5" r="4.5" fill="#C9C9C9" />
              <animated.circle style={animDots[1]} cx="4.5" cy="4.5" r="4.5" fill="#C9C9C9" />
            </g>
          </g>
          {/* ikona */}
          <animated.g style={animIcon}>
            {/* reka */}
            <g>
              <path
                stroke="#C9C9C9"
                d="M32 86v-4s7-4.5 7-10v-6M17 41L7 48.826c-2.828 2.536-4 3.984-4 7.826v13.283L3.002 86M1 94v-8h36v8"
              />
              <path
                stroke="#C9C9C9"
                d="M10 62.385l15.57-15.56c3.585-3.583 9.16 5.341 4.885 10.678l-4.885 4.882v5.835c0 7.626-6.64 7.78-7.785 7.78"
              />
            </g>
            {/* dokument */}
            <g>
              <path
                stroke="#094"
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M17 56.152V4.939C17 2.765 18.792 1 21 1h26l12 11.818v49.243C59 64.235 57.208 66 55 66H27"
              />
              <path stroke="#094" strokeLinecap="round" strokeLinejoin="round" d="M44 7v8h8" />
              <path stroke="#094" d="M29 27.5h24M29 34.5h16" />
            </g>
          </animated.g>
        </g>
      </svg>
    </div>
  );
};
