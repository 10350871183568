import get from 'lodash.get';
import { FileImageSharp } from '@content/types/api';

export const transformHomepageData = homepageData => {
  const homepageDataLanguages = {};

  homepageData.edges.forEach(edge => {
    const data = edge.node;
    const heroData =
      data.relationships &&
      data.relationships.field_components &&
      data.relationships.field_components.find(
        component => component.__typename === 'paragraph__hero_1'
      );
    const bulletsData =
      data.relationships &&
      data.relationships.field_components &&
      data.relationships.field_components.find(
        component => component.__typename === 'paragraph__bullets_container'
      );
    const recommendationData =
      data.relationships &&
      data.relationships.field_components &&
      data.relationships.field_components.find(
        component => component.__typename === 'paragraph__branches_recommendation'
      );
    const comprehensiveSolution =
      data.relationships &&
      data.relationships.field_components &&
      data.relationships.field_components.find(
        component => component.__typename === 'paragraph__comprehensive_solution'
      );
    const recommendedBlogPosts =
      data.relationships &&
      data.relationships.field_components &&
      data.relationships.field_components.find(
        component => component.__typename === 'paragraph__recommended_blog_posts'
      );

    homepageDataLanguages[data.langcode] = {
      seo: {
        title: get(data, 'field_meta_title', ''),
        description: get(data, 'field_meta_description', ''),
        image: get(data, 'relationships.field_main_image.localFile.publicURL'),
      },
      hero: heroData
        ? {
            title: get(heroData, 'field_hero_1_title.value', ''),
            subtitle: get(heroData, 'field_hero_1_subtitle.value', ''),
            imageAlt: get(heroData, 'field_hero_1_image.alt', ''),
            imageMobileAlt: get(heroData, 'field_hero_1_image_mobile.alt', ''),
            videoThumbnailAlt: get(heroData, 'field_video_thumbnail.alt', ''),
            button: get(heroData, 'field_hero_1_button', ''),
            text1: get(heroData, 'field_hero_1_text_1.value', ''),
            text2: get(heroData, 'field_hero_1_text_2.value', ''),
            video: get(heroData, 'field_hero_1_video', ''),
            image: get(
              heroData,
              'relationships.field_hero_1_image.localFile',
              null
            ) as FileImageSharp,
            imageMobile: get(
              heroData,
              'relationships.field_hero_1_image_mobile.localFile',
              null
            ) as FileImageSharp,
            videoThumbnail: get(
              heroData,
              'relationships.field_video_thumbnail.localFile',
              null
            ) as FileImageSharp,
          }
        : null,
      bullets: bulletsData
        ? get(bulletsData, 'relationships.field_bullets_item', []).map(bullet => {
            return {
              paragraph: get(bullet, 'field_text.value', ''),
              imageAlt: get(bullet, 'field_image.alt', ''),
              image: get(bullet.relationships, 'field_image.localFile', null) as FileImageSharp,
              buttonLabel: get(bullet, 'field_nc_button_text', ''),
              buttonHref: get(bullet, 'field_btn_url', ''),
              video: get(bullet, 'field_video', ''),
            };
          })
        : null,
      comprehensive: comprehensiveSolution
        ? {
            title: get(comprehensiveSolution, 'field_paragraph_headline.value', ''),
            subtitle: get(comprehensiveSolution, 'field_text.value', ''),
            items: get(
              comprehensiveSolution,
              'relationships.field_comprehensive_solution_ite',
              []
            ).map(item => ({
              title: get(item, 'field_paragraph_headline.value', ''),
              image: get(item.relationships, 'field_image.localFile', null) as FileImageSharp,
              imageAlt: get(item, 'field_image.alt', ''),
              button: get(item, 'field_nc_button_text', ''),
              hasOnline: get(item, 'field_is_online', false),
              online: get(item, 'field_is_online_text', ''),
            })),
          }
        : null,
      branchLarge: recommendationData
        ? {
            title: get(recommendationData, 'field_paragraph_headline.value', ''),
            paragraph: get(recommendationData, 'field_branches_text.value', ''),
            imageAlt: get(recommendationData, 'field_branches_image.alt', ''),
            isOnline: get(recommendationData, 'field_is_online', false),
            isOnlineText: get(recommendationData, 'field_is_online_text', ''),
            image: get(
              recommendationData,
              'relationships.field_branches_image.localFile',
              null
            ) as FileImageSharp,
            button: get(recommendationData, 'field_nc_button_text', ''),
            buttonHref: get(recommendationData, 'field_url', ''),
          }
        : null,
      branchBottom: {
        paragraph: get(data, 'field_mp_text.value', ''),
        imageAlt: get(data, 'field_mp_image.alt', ''),
        image: get(data, 'relationships.field_mp_image.localFile', null) as FileImageSharp,
        button: get(data, 'field_mp_button_text', ''),
      },
      cashLess: {
        paragraph: get(data, 'field_mp_text_footer.value', ''),
        imageAlt: get(data, 'field_mp_image_footer.alt', ''),
        image: get(data, 'relationships.field_mp_image_footer.localFile', null) as FileImageSharp,
        buttonLabel: get(data, 'field_settings_button_text', ''),
        buttonHref: get(data, 'field_btn_url', ''),
      },
      recommendedBlogPosts: recommendedBlogPosts 
        ? {
          headline: get(recommendedBlogPosts, 'field_headline', ''),
          readMoreButtonText: get(recommendedBlogPosts, 'field_read_more_button_text', ''),
          items: get(
            recommendedBlogPosts,
            'relationships.field_blog_posts',
            []
          ).map(item => ({
            title: get(item, 'title', ''),
            image: get(item.relationships, 'field_news_image.localFile', null) as FileImageSharp,
            introduction: get(item, 'field_news_body', ''),
            categories: item.relationships && item.relationships.field_news_categories
              ? [item.relationships.field_news_categories.name || '']
              : [],
            tags: get(item, 'relationships.field_news_tags', []).map(tag => tag.name) || [],
            date: get(item, 'created', ''),
            timeToRead: get(item, 'field_news_reading_time', ''),
            slug: item.path ? item.path.alias || item.id : item.id,
          })), 
        }
        : null,
    };
  });

  return homepageDataLanguages;
};
