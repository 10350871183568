import React from 'react';
import { useSpring, useSprings, animated, config } from 'react-spring';
import { Stylable } from '@content/types/general';
import { WaypointDependent } from '@animations/AnimationTriggerWaypoint';

interface Props extends Stylable, WaypointDependent {}

const strokeDasharrays = [300, 200, 100];
const initialDelay = 1000;
const stepsDelay = 400;

export default (props: Props) => {
  // lines animation
  const [animLines, animLinesSet] = useSprings(3, i => ({
    from: { strokeDashoffset: (i === 0 ? -1 : 1) * strokeDasharrays[i] },
    config: config.molasses,
  }));
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  animLinesSet((i: number, spring: any) => ({
    strokeDashoffset: props.sectionVisible ? 0 : (i === 0 ? -1 : 1) * strokeDasharrays[i],
    delay: initialDelay + i * stepsDelay,
  }));

  // dots animation
  const [animDots, animDotsSet] = useSprings(3, i => ({
    from: { opacity: 0 /*, transform: 'scale(0)'*/ },
    config: config.default,
  }));
  animDotsSet(i => ({
    opacity: props.sectionVisible ? 1 : 0,
    // transform: props.sectionVisible ? 'scale(1)' : 'scale(0)',
    delay: initialDelay + strokeDasharrays[i] * 3 + i * stepsDelay,
  }));

  // icon animation
  const [animIcon, animIconSet] = useSpring(i => ({
    from: { opacity: 0, transform: 'translate(3%, -3%)' },
    config: config.subtle,
  }));
  animIconSet({
    opacity: props.sectionVisible ? 1 : 0,
    transform: props.sectionVisible ? 'translate(0, 0)' : 'translate(3%, -3%)',
    delay: initialDelay + 1500,
  });

  return (
    <div className={props.className}>
      <svg xmlns="http://www.w3.org/2000/svg" width="530" height="314" viewBox="0 0 530 314">
        <g fill="none" fillRule="evenodd">
          {/* <!-- icon --> */}
          <animated.g style={animIcon}>
            <path
              stroke="#C9C9C9"
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M411 77.5h106M463.5 25v52M411 77.92C411 48.725 434.729 25 464 25s53 23.804 53 53M464 25s-28 11.343-28 53"
            />
            <path
              fill="#C9C9C9"
              fillRule="nonzero"
              d="M463.535 24.815a.5.5 0 0 1 .65-.28l.336.144c.133.06.276.126.43.198l.488.24c.982.491 2.075 1.109 3.25 1.861 3.352 2.146 6.702 4.941 9.828 8.46 5.521 6.219 9.735 14.014 12.12 23.552l-.12-.464a4.5 4.5 0 1 1-.976.217c-2.364-9.156-6.45-16.647-11.772-22.64-3.062-3.448-6.341-6.185-9.619-8.283a34.988 34.988 0 0 0-3.159-1.81c-.165-.083-.322-.16-.468-.229l-.409-.188-.299-.128a.5.5 0 0 1-.28-.65z"
            />
            <path
              stroke="#C9C9C9"
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M463 55c-28.593 0-36-14-36-14M500 39s-7.407 16-36 16M463.5 118V77M517 76a52.774 52.774 0 0 1-11.38 32.818m-17.705 14.493C480.73 126.95 472.605 129 464 129c-29.271 0-53-23.729-53-53M465 129s27-10.129 27-51M464 129s-28-11.343-28-53M488 103c-5.828-2.242-13.928-4-25-4M427 113s7.407-14 36-14"
            />
            <g stroke="#094" strokeLinecap="round" strokeLinejoin="round">
              <path d="M490 111l21 9.081M490 111l9.081 21M511 120.081l-11.002.917L499.08 132" />
            </g>
          </animated.g>
          <g transform="translate(184 1)">
            {/* <!-- line - white --> */}
            <animated.path
              strokeDasharray={strokeDasharrays[0]}
              strokeDashoffset={animLines[0].strokeDashoffset}
              stroke="#C9C9C9"
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M154.5 309c85.328 0 154.5-69.172 154.5-154.5M222.916 15.936C202.293 5.734 179.066 0 154.5 0 83.171 0 23.132 48.336 5.352 114.04"
            />
            <animated.circle style={animDots[0]} cx="154.5" cy="308.5" r="4.5" fill="#C9C9C9" />
            <animated.circle style={animDots[0]} cx="223.5" cy="16.5" r="4.5" fill="#C9C9C9" />

            {/* <!-- line-green --> */}
            <animated.path
              strokeDasharray={strokeDasharrays[1]}
              strokeDashoffset={animLines[1].strokeDashoffset}
              stroke="#094"
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M170.987 260.346c49.21-7.713 88.042-49.266 90.77-101.332M206.05 59.911c-13.876-7.697-29.71-12.444-46.672-13.333-33.555-1.758-64.292 11.958-85.1 34.832"
            />
            <animated.circle style={animDots[1]} cx="73.5" cy="81.5" r="4.5" fill="#094" />
            <animated.circle style={animDots[1]} cx="261.5" cy="158.5" r="4.5" fill="#094" />

            {/* <!-- line-short --> */}
            <animated.path
              strokeDasharray={strokeDasharrays[2]}
              strokeDashoffset={animLines[2].strokeDashoffset}
              stroke="#C9C9C9"
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M215.745 156.21a51.595 51.595 0 0 0-1.56-23.698c-4.802-15.704-16.404-27.477-30.645-33.13"
            />
          </g>
          <g>
            {/* <!-- line - outside short --> */}
            <animated.path
              strokeDasharray={strokeDasharrays[2]}
              strokeDashoffset={animLines[2].strokeDashoffset}
              stroke="#094"
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M33.043 210.069c-7.002-.123-13.708 3.496-17.01 9.914-3.4 6.611-2.252 14.37 2.288 19.951"
            />
            <g transform="translate(-6.718 193.836)">
              {/* <!-- line - outside long --> */}
              <animated.path
                strokeDasharray={strokeDasharrays[1]}
                strokeDashoffset={animLines[1].strokeDashoffset}
                stroke="#094"
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M33.963 6.613C24.55 6.6 15.45 11.733 10.865 20.648a25.447 25.447 0 0 0 2.624 27.43"
              />
              <animated.circle style={animDots[1]} cx="13.218" cy="47.664" r="4.5" fill="#094" />
            </g>
          </g>
        </g>
      </svg>
    </div>
  );
};
