import React from 'react';

import Page from '@components/layout/Page';
import HomeHero from '@components/home/HomeHero';
import HomeSplitSection1 from '@components/home/HomeSplitSection1';
import HomeSplitSection2 from '@components/home/HomeSplitSection2';
import HomeSplitSection3 from '@components/home/HomeSplitSection3';
import HomeProducts from '@components/home/HomeProducts';
import HomeBranches from '@components/home/HomeBranches';
import HomeSectionCashless from '@components/home/HomeSectionCashless';
import HomeSectionBlog from '@components/home/HomeSectionBlog';
import styled from '@emotion/styled';

export interface HomepageProps {
  langcode: string;
  urls: Array<string>;
}

const HeroWrapper = styled.div`
  position: relative;
`;

export default props => {
  return (
    <Page>
      <HeroWrapper>
        {props.homepageData.hero && (
          <HomeHero data={props.homepageData.hero} langcode={props.langcode} urls={props.urls} />
        )}
      </HeroWrapper>
      {props.homepageData.bullets &&
        props.homepageData.bullets.map((bullet, index) => {
          if (index === 0 || index % 1) {
            return (
              <HomeSplitSection1
                key={index}
                data={bullet}
                langcode={props.langcode}
                urls={props.urls}
              />
            );
          } else if (index === 1 || index % 2) {
            return (
              <HomeSplitSection2
                key={index}
                data={bullet}
                langcode={props.langcode}
                urls={props.urls}
              />
            );
          } else if (index === 2 || index % 3) {
            return (
              <HomeSplitSection3
                key={index}
                data={bullet}
                langcode={props.langcode}
                urls={props.urls}
              />
            );
          }
        })}

      <HomeProducts
        data={props.homepageData.comprehensive}
        langcode={props.langcode}
        urls={props.urls}
      />
      <HomeBranches
        branchLarge={props.homepageData.branchLarge}
        branchBottom={props.homepageData.branchBottom}
        langcode={props.langcode}
        urls={props.urls}
      />
      <HomeSectionCashless
        data={props.homepageData.cashLess}
        langcode={props.langcode}
        urls={props.urls}
      />
      {
        props.homepageData.recommendedBlogPosts && 
        <HomeSectionBlog 
          recommendedBlogPosts={props.homepageData.recommendedBlogPosts}
          langcode={props.langcode}
          urls={props.urls}
        />
      }
    </Page>
  );
};
