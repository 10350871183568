import React, { useState } from 'react';
import styled from '@emotion/styled';
import { colors, respondFrom, breakpoints, css, mixins } from '@styles';
import Image from 'gatsby-image';

import SectionSplit from '@components/common/SectionSplit';
import ButtonLink from '@components/common/ButtonLink';
import AnimationTriggerWaypoint from '@animations/AnimationTriggerWaypoint';
import AnimationHomeSection2 from '@animations/AnimationHomeSection2';
import { HomepageBulletsData } from '@content/types/homepage';
import * as utils from '@utils';
import VideoPlayer from '@components/common/VideoPlayer';

const SectionSplitStyled = styled(SectionSplit)`
  position: relative;

  ${respondFrom(
    breakpoints.md,
    css`
      &::before,
      &::after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        margin: auto;
        background-color: ${colors.green.dark};
        width: 1px;
      }

      &::before {
        top: 135px;
        height: 20px;
      }

      &::after {
        top: 175px;
        bottom: 0;
      }
    `
  )}

  ${respondFrom(
    breakpoints.xl,
    css`
      &::before {
        top: 185px;
        height: 20px;
      }

      &::after {
        top: 225px;
        bottom: 0;
      }
    `
  )}

  /* animations */
  ${respondFrom(
    breakpoints.md,
    css`
      &::before,
      &::after {
        ${mixins.transitionDefault}
        transform-origin: top center;
      }
      /* short line */
      &::before {
        transition-duration: 200ms;
        transition-timing-function: ease-in;
      }
      /* long line */
      &::after {
        transition-duration: 500ms;
        transition-delay: 200ms;
      }
    `
  )}
  &.invisible { /* added by AnimationTriggerWaypoint */
    &::before {
      opacity: 0;
      transform: translateY(-30px);
    }
    &::after {
      transform: scaleY(0);
    }
  }
  /* /animations */
`;

const StyledAnimationHomeSection2 = styled(AnimationHomeSection2)`
  position: absolute;
  top: 50%;
  left: 0%;
  width: 100%;
  height: 100%;
  /* z-index: -1; */

  transform: translate(11%, -71%);

  /* !super important for proper scaling */
  svg {
    height: 100%;
    width: auto;
  }
`;

interface SplitSectionProps {
  className?: string;
  data: HomepageBulletsData;
  langcode: string;
  urls: Array<string>;
}

const SplitSection1: React.FC<SplitSectionProps> = props => {
  const [visibility, setVisibility] = useState(false);

  return (
    <AnimationTriggerWaypoint onChange={setVisibility}>
      <SectionSplitStyled
        imageOnLeft={true}
        contentA={
          <>
            {props.data.video ? (
              <VideoPlayer
                image={props.data.image.childImageSharp}
                imageAlt={props.data.imageAlt}
                videoUrl={props.data.video}
              />
            ) : (
              props.data.image && (
                <Image {...props.data.image.childImageSharp} alt={props.data.imageAlt} />
              )
            )}
            <StyledAnimationHomeSection2 sectionVisible={visibility} />
          </>
        }
        contentB={
          <div>
            {utils.SafeHtml(props.data.paragraph)}

            {props.data.buttonHref &&
              props.data.buttonHref !== '' &&
              props.data.buttonLabel &&
              props.data.buttonLabel !== '' && (
                <ButtonLink
                  to={utils.langLink(props.langcode, props.data.buttonHref)}
                  icon="arrow-right"
                  color="text"
                >
                  {props.data.buttonLabel}
                </ButtonLink>
              )}
          </div>
        }
      >
        {props.children}
      </SectionSplitStyled>
    </AnimationTriggerWaypoint>
  );
};

export default SplitSection1;
