import React from 'react';
import styled from '@emotion/styled';

import { dimensions, colors } from '@styles';
import Container from '../layout/Container';
import HomeProductsItem from './HomeProductsItem';
import SectionHeaders from '@components/common/SectionHeader';

import { HomepageComprehensiveData } from '@content/types/homepage';
import * as utils from '@utils';

const StyledHomeProductsList = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;

  margin: 0 -${dimensions.boxesSpacing / 2}px;

  padding-bottom: 160px;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 1px;
    height: 108px;
    background-color: ${colors.green.dark};
  }
`;

interface HomeProductsProps {
  data: HomepageComprehensiveData;
  langcode: string;
  urls: Array<string>;
}

export default (props: HomeProductsProps) => (
  <Container>
    <div>
      <SectionHeaders
        title={utils.SafeHtml(props.data.title)}
        paragraph={utils.SafeHtml(props.data.subtitle)}
      />

      <StyledHomeProductsList>
        {props.data.items.map((item, index) => {
          return (
            <HomeProductsItem {...item} key={index} langcode={props.langcode} urls={props.urls} />
          );
        })}
      </StyledHomeProductsList>
    </div>
  </Container>
);
